$(function(){

    $('.nav-toggler').on('click', function(){

        $('.nav-menu').show();
        $('.header .overlay').show();

    });

    $('.reviews-slider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true
    });
    
    $('.header .overlay').on('click', function(){

        $('.nav-menu').hide();
        $('.header .overlay').hide();

    });

    $('.offer-column .button').on('click', function(e){
        var $target = e.target;
        var $popup = ($($target).parents(':eq(1)')).next();
        if( $($target).is( "img") ){
            $popup = ($($target).parents(':eq(2)')).next();
        }
        $popup.css('display','flex');
        $('.offer .overlay').show();
    });

    $('.offer .overlay').on('click', function(){

        $('.offer .overlay').hide();
        $('.offer-popup').hide();

    });

    $('.popup .button').on('click', function(){

        $('.offer .overlay').hide();
        $('.offer-popup').hide();

    });

    $('.x').on('click', function(){

        $('.offer .overlay').hide();
        $('.offer-popup').hide();

    });

    $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
        ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                scrollTop: target.offset().top - 0
                }, 1000, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                };
                });
            }
        }
    });

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy"
    });

});